import * as React from 'react';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';


const Carousel = ({ items, settings }) => {
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: settings.speed,
    slidesToShow: settings.shownItems.lg,
    slidesToScroll: settings.scrolledItems.lg,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: settings.shownItems.md,
          slidesToScroll: settings.scrolledItems.md,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: settings.shownItems.sm,
          slidesToScroll: settings.scrolledItems.sm,
        },
      },
    ],
  };

  return (
    <div className="mb-8">
      <Slider {...sliderSettings}>
        {items.map(({ id, node }) => (
          <div key={id}>{node}</div>
          ))}
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  settings: PropTypes.shape({
    seepd: PropTypes.number.isRequired,
    shownItems: PropTypes.shape({
      lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
      md: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
      sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
    }).isRequired,
    scrolledItems: PropTypes.shape({
      lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
      md: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
      sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Carousel;