import * as React from 'react';
import styled from 'styled-components';

const StyledStrong = styled.strong.attrs(() => ({
  className: 'font-semibold',
}))``;

const Strong = ({ children }) => (
  <StyledStrong>
    {children}
  </StyledStrong>
);

export default Strong;