import * as React from 'react';

const SecondaryButton = ({ children, className, ...props }) => {
  const color = props.disabled ? 'gray-500' : 'red-500';
  return (
    <button
      className={`text-sm text-${color} tracking-wider border border-${color} uppercase font-medium px-4 py-2 rounded ${props.disabled ? 'cursor-not-allowed' : 'hover:text-red-700 hover:border-red-700 focus:outline-none focus:shadow-outline'} ${className}`} {...props}>
      {children}
    </button>
  );
};


export default SecondaryButton;