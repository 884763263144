import * as React from 'react';
import styled from 'styled-components';
import HeroTitle from '../../atoms/HeroTitle/HeroTitle';
import BackgroundImage from '../../atoms/BackgroundImage/BackgroundImage';
import PropTypes from 'prop-types';
import GoogleMap from '../GoogleMap/GoogleMap';

const StyledHeroBanner = styled.div.attrs(() => ({
  className: 'overflow-hidden h-160',
}))``;

const HeroBanner = ({ title, subtitle, lightBackground, background }) => {
  return (
    <StyledHeroBanner>{renderElement(title, subtitle, lightBackground, background)}</StyledHeroBanner>
  );
};

const renderElement = (title, subtitle, lightBackground, background) => {
  const type = background['__typename'];
  switch (type) {
    case 'ContentfulImage': {
      const { image, altText } = background;
      return (
        <BackgroundImage image={image.fluid} altText={altText}>
          {!title || <HeroTitle title={title} level={1} light={!lightBackground} />}
          {!subtitle || <HeroTitle title={subtitle} level={2} light={!lightBackground} />}
        </BackgroundImage>
      );
    }
    case 'ContentfulMap': {
      const { mapCenter, zoomLevel, markers } = background;
      return (
        <GoogleMap center={mapCenter} zoomLevel={zoomLevel} markers={markers} />
      );
    }
    default: {
      return (<div>UPS</div>);
    }
  }
};

HeroBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lightBackground: PropTypes.bool.isRequired,
  background: PropTypes.oneOfType([
    PropTypes.shape({
      __typename: PropTypes.oneOf(['ContentfulImage']).isRequired,
      altText: PropTypes.string.isRequired,
      image: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
    PropTypes.shape({
      __typename: PropTypes.oneOf(['ContentfulMap']).isRequired,
      mapCenter: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lon: PropTypes.number.isRequired,
      }).isRequired,
      zoomLevel: PropTypes.number.isRequired,
      markers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        position: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lon: PropTypes.number.isRequired,
        }).isRequired,
        description: PropTypes.shape({
          json: PropTypes.object.isRequired,
        }),
      })),
    }),
  ]).isRequired,
};

HeroBanner.defaultProps = {
  lightBackground: false,
};

export default HeroBanner;