import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import InputField from '../../../atoms/Form/InputField';
import FormField from '../../../atoms/Form/FormField';
import Label from '../../../atoms/Form/Label';
import PrimaryButton from '../../../atoms/Button/PrimaryButton';
import SecondaryButton from '../../../atoms/Button/SecondaryButton';
import { newsletterSignup } from '../../../../helpers/api';
import Message from '../../../atoms/Message/Message';
import Honeypot from '../../../atoms/Form/Honeypot';

const NewsletterSignupForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({});

  const honeyPot = {
    name: 'faxNumber',
    value: ''
  };

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        [honeyPot.name]: honeyPot.value
      }}
      onReset={() => {
        setStatus({});
      }}
      onSubmit={async (values, { resetForm }) => {
        if(!!values[honeyPot.name]) {
          return;
        }
        setSubmitting(true);
        setStatus({
          type: 'info',
          message: 'Submitting your subscription request. Please wait.',
        });
        try {
          await newsletterSignup(values);
          resetForm();
          setStatus({
            type: 'success',
            message: `Thank you ${values.firstName} for registering to our newsletter.`,
          });
          setTimeout(() => setStatus({}), 4000);
        } catch (e) {
          setStatus({
            type: 'error',
            message: `Ups, the following error occurred: ${e.message}`,
          });
        } finally {
          setSubmitting(false);
        }
      }}>
      <Form className="bg-white shadow-lg p-8 rounded">
        <h4 className="text-lg font-semibold tracking-wide">Subscribe to our newsletter</h4>
        <div className="mt-6">
          <FormField className="flex flex-col items-baseline my-4 md:flex-row">
            <Label className="mb-1 w-32 md:mr-2" htmlFor="email" optional>Email</Label>
            <Field as={InputField} name="email" type="text" placeholder="Your email address" />
          </FormField>
          <FormField className="flex flex-col items-baseline my-4 md:flex-row">
            <Label className="mb-1 w-32 md:mr-2" htmlFor="firstName" optional>First Name</Label>
            <Field as={InputField} name="firstName" type="text" placeholder="Your first name" />
          </FormField>
          <FormField className="flex flex-col items-baseline my-4 md:flex-row">
            <Label className="mb-1 w-32 md:mr-2" htmlFor="lastName" optional>Last Name</Label>
            <Field as={InputField} name="lastName" type="text" placeholder="Your last name" />
          </FormField>
          <FormField className="flex flex-col items-baseline my-4 md:flex-row">
            <Label className="mb-1 w-32 md:mr-2" htmlFor="company" optional>Company</Label>
            <Field as={InputField} name="company" type="text" placeholder="Optional" />
          </FormField>
          <FormField className="flex flex-col items-baseline my-4 md:flex-row">
            <Field as={Honeypot} type="text" name={honeyPot.name} />
          </FormField>
          <div className="mt-4 text-right">
            <SecondaryButton type="reset" disabled={submitting}>Reset</SecondaryButton>
            <PrimaryButton className="ml-4" type="submit" disabled={submitting}>Subscribe</PrimaryButton>
          </div>
          <div className={`mt-8 ${status.message ? 'block' : 'hidden'}`}>
            <Message type={status.type}>{status.message}</Message>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default NewsletterSignupForm;