import * as React from 'react';
import styled from 'styled-components';

import { FaExternalLinkAlt } from 'react-icons/fa';

const StyledExternalLink = styled.a.attrs(() => ({
  className: 'text-red-500 hover:text-blue-500',
}))``;

const StyledIcon = styled(FaExternalLinkAlt).attrs(() => ({
  className: 'inline-block',
}))`
  transform: translateY(-0.125em);
  font-size: 80%;
`;


const ExternalLink = ({ href, children }) => (
  <StyledExternalLink href={href} target="_blank" rel="noopener noreferrer">
    {children} <StyledIcon />
  </StyledExternalLink>
);

export default ExternalLink;