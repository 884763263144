import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from '../../atoms/Button/SecondaryButton';

const List = ({ items, initialItemCount, showMoreCount }) => {

  const [visibleItemCount, setVisibleItemCount] = useState(initialItemCount);

  return (
    <ul>
      {items.slice(0, visibleItemCount).map(({ id, node }) => (
        <li className="w-full" key={id}>{node}</li>
      ))}

      <div className="text-center mt-24">
        {
          visibleItemCount < items.length ?
            <SecondaryButton
              onClick={() => {
                setVisibleItemCount(Math.min(items.length, visibleItemCount + showMoreCount));
              }}>
              Show more
            </SecondaryButton> : undefined
        }
      </div>
    </ul>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    node: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  })).isRequired,
  initialItemCount: PropTypes.number,
  showMoreCount: PropTypes.number,
};

List.defaultProps = {
  items: [],
  initialItemCount: 5,
  showMoreCount: 5,
};

export default List;
