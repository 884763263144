import * as React from 'react';
import PropTypes from 'prop-types';

import { TiWarningOutline, TiInfoOutline, TiTimesOutline, TiTickOutline } from 'react-icons/ti';

const Message = ({ type, children, className}) => {
  let color;
  let Icon;
  switch (type) {
    case('success'): {
      color = 'green';
      Icon = TiTickOutline;
      break;
    }
    case('warning'): {
      color = 'yellow';
      Icon = TiWarningOutline;
      break;
    }
    case('error'): {
      color = 'red';
      Icon = TiTimesOutline;
      break;
    }
    default: {
      color = 'blue';
      Icon = TiInfoOutline;
      break;
    }
  }
  return (
    <div className={`px-4 py-2 text-sm font-medium text-${color}-700 bg-${color}-200 border border-${color}-700 rounded`}>
      <span className="inline-block mr-2 align-middle"><Icon /></span>{ children }
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

Message.defaultProps = {
  type: 'info'
};

export default Message;