import * as React from 'react';
import PropTypes from 'prop-types';

const Grid = ({ items, itemWidths }) => {
  return (
    <div className="flex justify-around items-stretch flex-wrap flex-col md:flex-row">
      {items.map(({ id, node }) => (
        <div
          key={id}
          className={`
          p-4
          w-full 
          ${!!itemWidths.sm ? `sm:w-${itemWidths.sm}` : ''} 
          ${!!itemWidths.md ? `md:w-${itemWidths.md}` : ''} 
          ${!!itemWidths.lg ? `lg:w-${itemWidths.lg}` : ''}           
          `}
        >
          {node}
        </div>
      ))}
    </div>
  );
};

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    node: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  })).isRequired,
  itemWidths: PropTypes.shape({
    lg: PropTypes.oneOf(['full', '1/2', '1/3', '1/4', '1/5', '1/6']).isRequired,
    md: PropTypes.oneOf(['full', '1/2', '1/3', '1/4', '1/5', '1/6']).isRequired,
    sm: PropTypes.oneOf(['full', '1/2', '1/3', '1/4', '1/5', '1/6']).isRequired,
  }),
};

Grid.defaultProps = {
  items: [],
  itemWidth: {
    lg: 'full',
  },
};


export default Grid;
