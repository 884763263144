const API_URL = process.env.API_ENDPOINT;

const createPostJsonRequest = body => ({
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});


const newsletterSignup = async (data) => {
  const request = createPostJsonRequest(data);
  const response = await fetch(`/api/subscription`, request);
  if(response.status >= 400) {
    const json = await response.json();
    throw Error(json.message)
  }
};

const sendContactRequest = async data => {
  const request = createPostJsonRequest(data);
  const response = await fetch(`/api/contact`, request);
  if(response.status >= 400) {
    const json = await response.json();
    throw Error(json.message)
  }
};

export {
  newsletterSignup,
  sendContactRequest
};
