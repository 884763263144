import * as React from 'react';

const DropDown = ({ options, className, ...props }) => (
  <div className="w-full relative">
    <select
      className={`shadow bg-white appearance-none border w-full px-3 py-2 rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${className}`}
        {...props}
    >
      {options.map(option => {
        return (<option key={option.value} value={option.value}>{option.label}</option>);
      })}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
);

export default DropDown;