import * as React from 'react';
import styled from 'styled-components';

const StyledOrderedList = styled.ol.attrs(() => ({
  className: 'list-decimal',
}))``;

const OrderedList = ({ children }) => (
  <StyledOrderedList>
    { children }
  </StyledOrderedList>
);

export default OrderedList;