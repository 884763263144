import * as React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledContainer = styled.div.attrs(() => ({
  className: 'relative h-full',
}))``;


const StyledBackgroundImage = styled.div.attrs(() => ({
  className: 'absolute top-0 bottom-0 w-full z-0',
}))`      
  & > div {
    height: 100%;
  } 
`;

const StyledContent = styled.div.attrs(() => ({
  className: 'absolute w-full px-4 z-10 text-center',
}))`  
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const BackgroundImage = ({ image, altText, children }) => (
  <StyledContainer>
    <StyledBackgroundImage>
      <Img fluid={image} title={altText} alt  ={altText} />
    </StyledBackgroundImage>
    <StyledContent>
      {children}
    </StyledContent>
  </StyledContainer>
);
export default BackgroundImage;