import * as React from 'react';

import EmployeeCard from '../components/molecules/EmployeeCard/EmployeeCard';
import ProductCard from '../components/molecules/ProductCard/ProductCard';
import Grid from '../components/molecules/Grid/Grid';
import ProductDetail from '../components/molecules/ProductDetail/ProductDetail';
import NewsArticleCard from '../components/molecules/NewsArticleCard/NewsArticleCard';
import List from '../components/molecules/List/List';
import NewsArticleListItem from '../components/molecules/NewsArticleListItem/NewsArticleListItem';
import EmployeeInline from '../components/molecules/EmployeeInline/EmployeeInline';
import ImageEmbedded from '../components/molecules/ImageEmbedded/ImageEmbedded';
import { renderCustomModule } from './customModules';
import Carousel from '../components/molecules/Carousel/Carousel';
import PartnerItem from '../components/molecules/PartnerItem/PartnerItem';
import TextItem from '../components/molecules/TextItem/TextItem';

const GRID = 'grid';
const LIST = 'list';
const SLIDER = 'slider';
const DETAIL = 'detail';
const EMBEDDED = 'embedded';
const INLINE = 'inline';

const ContentfulGrid = ({ itemWidthSmall, itemWidthMedium, itemWidthLarge, contents }) => (
  <Grid itemWidths={{
    sm: itemWidthSmall,
    md: itemWidthMedium,
    lg: itemWidthLarge,
  }} items={contents.map(item => ({
    id: item.id,
    node: renderItem(item, GRID),
  }))} />
);

const ContentfulList = ({ maximumListSize, contents }) => (
  <List initialItemCount={maximumListSize} showMoreCount={maximumListSize} items={contents.map(item => ({
    id: item.id,
    node: renderItem(item, LIST),
  }))} />
);

const ContentfulDetail = ({ content }) => (
  <>
    {renderItem(content, DETAIL)}
  </>
);

const ContentfulSlider = ({
                            shownItemsLarge,
                            shownItemsMedium,
                            shownItemsSmall,
                            scrolledItemsLarge,
                            scrolledItemsMedium,
                            scrolledItemsSmall,
                            speed,
                            contents,
                          }) => {
  return (
    <Carousel
      items={contents.map(item => ({
        id: item.id,
        node: renderItem(item, SLIDER),
      }))}
      settings={{
        speed: speed,
        shownItems: {
          lg: shownItemsLarge,
          md: shownItemsMedium,
          sm: shownItemsSmall,
        },
        scrolledItems: {
          lg: scrolledItemsLarge,
          md: scrolledItemsMedium,
          sm: scrolledItemsSmall,
        },
      }}
    />
  );
};

export const renderSectionContent = (sectionContent) => {
  if (!sectionContent) {
    return undefined;
  }
  const type = sectionContent['__typename'];
  const SectionContent = contentfulComponents[type]();

  return (<SectionContent {...sectionContent} />);
};

export const renderInlineEntry = (node, children) => {
  const typeId = node.data.target.sys.contentType.sys.id;
  const type = `Contentful${typeId.charAt(0).toUpperCase() + typeId.slice(1)}`;
  const InlineEntry = contentfulComponents[type](INLINE);
  return (
    <InlineEntry {...node.data.target.fields} />
  );
};

export const renderEmbeddedEntry = (node, children) => {
  const typeId = node.data.target.sys.contentType.sys.id;
  const type = `Contentful${typeId.charAt(0).toUpperCase() + typeId.slice(1)}`;
  const InlineEntry = contentfulComponents[type](EMBEDDED);
  return (
    <InlineEntry {...node.data.target.fields} />
  );
};

const renderItem = (item, type) => {
  const Item = contentfulComponents[item['__typename']](type);
  return (
    <Item {...item} />
  );
};

const contentfulComponents = {
  ContentfulContentGrid: () => ContentfulGrid,
  ContentfulContentList: () => ContentfulList,
  ContentfulContentSlider: () => ContentfulSlider,
  ContentfulContentDetail: () => ContentfulDetail,
  ContentfulEmployee: (type) => {
    switch (type) {
      case GRID:
        return EmployeeCard;
      case INLINE:
        return EmployeeInline;
      default:
        return EmployeeCard;
    }
  },
  ContentfulProduct: (type) => {
    switch (type) {
      case GRID:
        return ProductCard;
      case DETAIL:
        return ProductDetail;
      default:
        return ProductCard;
    }
  },
  ContentfulNewsArticle: (type) => {
    switch (type) {
      case GRID:
        return NewsArticleCard;
      case LIST:
        return NewsArticleListItem;
      default:
        return NewsArticleCard;
    }
  },
  ContentfulImage: (type) => {
    switch (type) {
      case EMBEDDED:
        return ImageEmbedded;
      default:
        return ImageEmbedded;
    }
  },
  ContentfulPartner: (type) => {
    switch (type) {
      case SLIDER:
        return PartnerItem;
      default:
        return PartnerItem;
    }
  },
  ContentfulText: (type) => {
    return TextItem;
  },
  ContentfulCustomModule: (type) => ({ moduleType, moduleIdentifier }) => (
    renderCustomModule(type, moduleType, moduleIdentifier)
  ),
};

