import * as React from 'react';
import { Link } from 'gatsby';
import RichText from '../RichText/RichText';
import Img from 'gatsby-image';

const ProductCard = ({ title, productType, productImage, summary, slug }) => {
  const color = productType === 'Product' ? 'blue' : 'green';
  return (
    <div className="flex flex-col justify-between h-full bg-white rounded overflow-hidden shadow">
      <div className="relative shadow">
        <Img fluid={productImage.image.fluid} alt=    {productImage.image.altText} />
        <Link to={slug} className="absolute bg-black top-0 w-full h-full opacity-0 hover:opacity-75 transition ease-in duration-200">
        <span className="absolute block text-white uppercase text-xs font-bold" style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>more</span>
        </Link>
      </div>
      <div className="p-4 justify-between">
        <div className="flex items-baseline">
          <h3 className="font-bold text-xl mt-2">{title}</h3>
          <div className={`ml-2 text-xs font-bold uppercase text-${color}-700 bg-${color}-200 rounded-full px-2`}>
            {productType}
          </div>
        </div>
        <div className="mt-2 text-gray-800 leading-snug">
          <RichText json={summary.json} />
        </div>
      </div>
      <div className="p-4">
        <Link className="block text-right text-sm text-red-500 font-bold uppercase hover:text-red-600 tracking-wide" to={slug}>Read more</Link>
      </div>
    </div>
  );

}
export default ProductCard;