import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FaMapMarkerAlt } from 'react-icons/fa';

const StyledMapMarker = styled.div.attrs(() => ({
  className: 'relative',
}))``;

const StyledIcon = styled(FaMapMarkerAlt).attrs(() => ({
  className: 'text-3xl text-red-500',
}))``;

const StyledTextBox = styled.div.attrs(() => ({
  className: 'text-sm leading-snug absolute text-center block bg-white px-4 py-3 md:px-6 md:py-4 rounded shadow-lg',
}))`
    width: max-content;
    top: -1.5rem;
    left: 1rem;
    transform: translate(-50%, -100%);
    
    &:before {      
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: .5rem solid transparent;      
      border-right: .5rem solid #FFF;      
      border-top: .5rem solid transparent;      
      border-bottom: .5rem solid #FFF;      
      left: 50%; 
      bottom: 0;
      transform: translate(-50%, 50%) rotate(45deg)
      
    }
`;

const MapMarker = ({ children }) => (
  <StyledMapMarker>
    <StyledIcon />
    <StyledTextBox>
      {children}
    </StyledTextBox>
  </StyledMapMarker>
);

MapMarker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MapMarker;