import * as React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.div.attrs(({ level }) => {
  switch (level) {
    case 1: {
      return {
        as: `h${level}`,
        className: 'text-gray-900 text-5xl mb-8 font-thin tracking-wide',
      };
    }
    case 2: {
      return {
        as: `h${level}`,
        className: 'text-gray-900 text-3xl mb-4 font-semibold tracking-wide',
      };
    }
    case 3: {
      return {
        as: `h${level}`,
        className: 'text-gray-900 text-2xl mb-2',
      };
    }
    case 4: {
      return {
        as: `h${level}`,
        className: 'text-gray-900 text-xl mb-2',
      };
    }
    case 5: {
      return {
        as: `h${level}`,
        className: 'text-gray-900 text-base mb-2 font-semibold',
      };
    }
    case 6: {
      return {
        as: `h${level}`,
        className: 'text-gray-900 text-base mb-2 font-semibold underline',
      };
    }
    default: {
      throw Error('Only levels 1 to 6 are supported');
    }
  }


})``;

const Heading = ({ level, children }) => (
  <StyledHeading level={level}>
    {children}
  </StyledHeading>
);

export default Heading;