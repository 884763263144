import * as React from 'react';
import styled from 'styled-components';

const StyledParagraph = styled.p.attrs(() => ({
  className: 'my-4',
}))``;

const Paragraph = ({ children }) => (
  <StyledParagraph>
    { children }
  </StyledParagraph>
);

export default Paragraph;