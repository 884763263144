import * as React from 'react';
import styled from 'styled-components';
import Heading from '../../atoms/Heading/Heading';
import RichText from '../RichText/RichText';
import Img from 'gatsby-image';
import PhotoFrameImage from '../../atoms/PhotoFrameImage/PhotoFrameImage';

const getPriceInfo = (price, currency) => {
  if (price <= 0) {
    return 'Price upon request';
  } else {
    return `${currency} ${price}`;
  }
};

const StyledProductDetail = styled.div.attrs(() => ({
  className: 'flex flex-col lg:flex-row',
}))``;

const StyledProductImage = styled.div.attrs(() => ({
  className: 'flex-1 mb-8 mr-0 lg:mr-8 lg:mb-0',
}))``;

const StyledProductContent = styled.div.attrs(() => ({
  className: 'flex-1 ml-0 lg:ml-8',
}))``;

const StyledProductPriceInfo = styled.div.attrs(() => ({
  className: 'text-xs font-semibold tracking-wide uppercase text-blue-400 text-right mb-8 pb-6 border-b border-gray-300',
}))`
  
`;

const StyledProductDescription = styled.div.attrs(() => ({
  className: 'text-gray-900',
}))``;

const StyledMobileProductTitle = styled.div.attrs(() => ({
  className: 'block lg:hidden',
}))``;

const StyledProductTitle = styled.div.attrs(() => ({
  className: 'hidden lg:block',
}))``;

const ProductDetail = ({ title, description, productImage, price, currency }) => (
  <>
    <StyledProductPriceInfo>{getPriceInfo(price, currency)}</StyledProductPriceInfo>
    <StyledMobileProductTitle>
      <Heading level={2}>{title}</Heading>
    </StyledMobileProductTitle>
    <StyledProductDetail>
      <StyledProductImage>
        <PhotoFrameImage fluid={productImage.image.fluid} alt={productImage.image.altText} />
      </StyledProductImage>
      <StyledProductContent>
        <StyledProductTitle>
          <Heading level={2}>{title}</Heading>
        </StyledProductTitle>
        <StyledProductDescription>
          <RichText json={description.json} />
        </StyledProductDescription>
      </StyledProductContent>
    </StyledProductDetail>
  </>
);

export default ProductDetail;