import * as React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RichText from '../RichText/RichText';
import { FaLinkedin } from 'react-icons/fa';

const StyledCard = styled.div.attrs(() => ({
  className: 'h-full rounded m-auto bg-white shadow-lg text-center text-gray-900 text-base flex flex-col',
}))`
  
`;

const StyledCardHeader = styled.div.attrs(() => ({
  className: 'pb-4',
}))``;

const StyledCardTitle = styled.div.attrs(() => ({
  className: 'pt-6 text-lg font-bold',
}))`
  &::after {
    display: block;
    content: '';
    width: 12rem;
    height: 1px;
    margin: .25rem auto;   
    background: rgba(0,0,0,0.3);
  }
`;

const StyledCardSubtitle = styled.div.attrs(() => ({
  className: 'text-sm pb-4 text-gray-600 font-hairline',
}))``;

const StyleCardUnderstate = styled.span.attrs(() => ({
  className: 'text-lg text-gray-600 font-hairline',
}))``;


const StyledCardImage = styled.div.attrs(() => ({
  className: 'relative border-t border-b border-gray-300',
}))`
  a {
    transition: all 400ms ease;
  }
  
  &:hover a, &:active a {
    opacity: 1;  
    width: auto;
    left: 1rem;
    transform: scale(1);
  }
`;

const StyledCardContent = styled.div.attrs(() => ({
  className: 'flex-1 pt-4 pb-6 px-4',
}))``;

const StyledStyledLinkedInContainer = styled.a.attrs(() => ({
  className: 'block absolute px-2 py-2 bg-blue-700 rounded-full opacity-0 hover:bg-white border-blue-700 border text-white text-xl hover:text-blue-700 hover:cursor-pointer',
}))`
  bottom: 1rem;
  left: 0;  
  transform: scale(0)
`;

const EmployeeCard = ({ title, firstName, lastName, position, content, profilePicture, linkedInProfile }) => {
  return (
    <StyledCard>
      <StyledCardHeader>
        <StyledCardTitle><StyleCardUnderstate>{title}</StyleCardUnderstate> {firstName} {lastName}</StyledCardTitle>
        <StyledCardSubtitle>{position}</StyledCardSubtitle>
      </StyledCardHeader>
      <StyledCardImage>
        <Img fluid={profilePicture.image.fluid} />
        {!linkedInProfile || (
          <StyledStyledLinkedInContainer href={linkedInProfile} target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </StyledStyledLinkedInContainer>
        )}
      </StyledCardImage>
      <StyledCardContent>
        <RichText json={content.json} />
      </StyledCardContent>
    </StyledCard>
  );
};

export default EmployeeCard;
