import * as React from 'react';
import styled from 'styled-components';

const StyledEmphasize = styled.em.attrs(() => ({
  className: 'inline not-italic text-blue-700',
}))``;

const Emphasize = ({ children }) => (
  <StyledEmphasize>
    { children }
  </StyledEmphasize>
);

export default Emphasize;