import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from '../../atoms/MapMarker/MapMarker';
import PropTypes from 'prop-types';
import RichText from '../RichText/RichText';

const GoogleMap = ({ center, zoomLevel, markers }) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_APIKEY }}
        defaultCenter={{
          lat: center.lat,
          lng: center.lon,
        }}
        defaultZoom={zoomLevel}
        options={{
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
        }}
      >
        {
          markers.map(marker => (
            <MapMarker
              key={marker.id}
              lat={marker.position.lat}
              lng={marker.position.lon}
            >
              <RichText json={marker.description.json} />
            </MapMarker>
          ))
        }
      </GoogleMapReact>
    </div>
  );
};


// static defaultProps = {
//   center: {
//     lat: 46.7971331,
//     lng: 7.1482657000000245
//   },
//   markers: [
//     {
//       lat: 46.7971331,
//       lng: 7.1482657000000245,
//       text: 'Test'
//     }
//   ],
//   zoom: 15,
//   options: {
//     mapTypeControl: false,
//     zoomControl: false,
//     fullscreenControl: false,
//   },
// };


GoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
  }).isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

GoogleMap.defaultProps = {};

export default GoogleMap;