import * as React from 'react';
import styled from 'styled-components';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ExternalLink from '../../atoms/ExternalLink/ExternalLink';
import Strong from '../../atoms/Strong/Strong';
import Heading from '../../atoms/Heading/Heading';
import Emphasize from '../../atoms/Emphasize/Emphasize';
import Paragraph from '../../atoms/Paragraph/Paragraph';
import InternalLink from '../../atoms/InternalLink/InternalLink';
import OrderedList from '../../atoms/OrderedList/OrderedList';
import UnorderedList from '../../atoms/UnorderedList/UnorderedList';
import { renderEmbeddedEntry, renderInlineEntry } from '../../../helpers/contentfulComponents';

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (<Heading level={1}>{children}</Heading>),
    [BLOCKS.HEADING_2]: (node, children) => (<Heading level={2}>{children}</Heading>),
    [BLOCKS.HEADING_3]: (node, children) => (<Heading level={3}>{children}</Heading>),
    [BLOCKS.HEADING_4]: (node, children) => (<Heading level={4}>{children}</Heading>),
    [BLOCKS.HEADING_5]: (node, children) => (<Heading level={5}>{children}</Heading>),
    [BLOCKS.HEADING_6]: (node, children) => (<Heading level={6}>{children}</Heading>),
    [BLOCKS.PARAGRAPH]: (node, children) => (<Paragraph>{children}</Paragraph>),
    [BLOCKS.OL_LIST]: (node, children) => (<OrderedList>{children.map(child => (<li>{child}</li>))}</OrderedList>),
    [BLOCKS.UL_LIST]: (node, children) => (<UnorderedList>{children}</UnorderedList>),
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      return renderEmbeddedEntry(node, children);
    },
    [INLINES.HYPERLINK]: (node, children) => (<ExternalLink href={node.data.uri}>{children}</ExternalLink>),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (<InternalLink to={node.data.target.fields.slug['en-US']}>{children}</InternalLink>);
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      return renderInlineEntry(node, children);
    }
  },
  renderMark: {
    [MARKS.BOLD]: text => (<Strong>{text}</Strong>),
    [MARKS.ITALIC]: text => (<Emphasize>{text}</Emphasize>),
  },
};

const RichText = ({ json, className }) => {
  return (
    <div className={`${className}`}>
      {documentToReactComponents(json, options)}
    </div>
  );
};

export default RichText;