import * as React from 'react';
import Img from 'gatsby-image';

const PartnerItem = ({ name , partnerType, logo, url }) => {
  const color = partnerType === 'Partner' ? 'blue' : 'green';

  return (
    <div className="relative px-8 py-16 w-full m-auto border bg-white shadow rounded transform scale-90 hover:scale-100 transition duration-500 ease-in-out">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={`absolute top-0 right-0 mr-4 mt-4 text-xs font-bold uppercase text-${color}-700 bg-${color}-200 rounded-full px-2`}>
          {partnerType}
        </div>
        <Img className="" fluid={logo.image.fluid} alt={logo.image.altText} />
      </a>
    </div>
  )
};

export default PartnerItem;