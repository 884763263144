import * as React from 'react';
import Img from 'gatsby-image';
import RichText from '../RichText/RichText';

const NewsArticleListItem = ({ title, content, headlineImage, publicationStart }) => (
  <>
    <div className="w-full m-auto lg:w-10/12 mt-24 pb-24 border-b border-gray-200">
      <div className="w-full md:w-8/12">
        <div className="relative pb-2/3 rounded shadow overflow-hidden">
          <Img className="w-full h-full border-8 border-white rounded"
               style={{
                 position: 'absolute',
               }}
               fluid={headlineImage.image.fluid}
               alt={headlineImage.image.altText}
          />
        </div>
      </div>
      <div className="relative w-10/12 md:w-8/12 z-10 ml-1/12 md:ml-4/12 -mt-8">
        <div className="p-4 bg-white shadow-lg rounded border">
          <div className="text-xs uppercase font-bold tracking-wide text-gray-600"> {publicationStart}</div>
          <h3 className="font-bold">{title}</h3>
          <div className="mt-2 text-gray-800 leading-snug">
            <RichText json={content.json} />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default NewsArticleListItem;