import * as React from 'react';
import styled from 'styled-components';

const StyledUnorderedList = styled.ol.attrs(() => ({
  className: 'list-disc pl-6 my-4 leading-loose',
}))``;

const UnorderedList = ({ children }) => (
  <StyledUnorderedList>
    { children }
  </StyledUnorderedList>
);

export default UnorderedList;