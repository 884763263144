import * as React from 'react';
import { graphql } from 'gatsby';

import DefaultLayout from '../components/templates/DefaultLayout';
import HeroBanner from '../components/molecules/HeroBanner/HeroBanner';
import SEO from '../components/atoms/SEO/SEO';
import Section from '../components/molecules/Section/Section';
import RichText from '../components/molecules/RichText/RichText';
import { renderSectionContent } from '../helpers/contentfulComponents';

const Page = ({ data: { page } }) => {
  return (
    <DefaultLayout>
      <>
        <SEO title={page.seoTitle} />
        {!page.heroBanner || (<HeroBanner {...page.heroBanner} />)}
        {!page.sections || page.sections.map(({ id, type, title, lead, content }) => {
          return (
            <Section key={id}
                     title={title}
                     lead={lead?.json ? <RichText json={lead.json} /> : undefined}
                     type={type}>
              {renderSectionContent(content)}
            </Section>
          );
        })}
      </>
    </DefaultLayout>
  );
};

export default Page;

export const query = graphql`
  query fetchPage($slug: String) {
    page: contentfulPage(slug: {eq: $slug}) {
      id
      ...pageContent
    }
  }
`;
