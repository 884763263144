import * as React from 'react';
import RichText from '../RichText/RichText';
import Img from 'gatsby-image';

const NewsArticleCard = ({ title, content, headlineImage, publicationStart }) => (
  <div className="flex flex-col justify-between h-full bg-white rounded overflow-hidden shadow">
    <div className="relative shadow">
      <Img fluid={headlineImage.image.fluid} alt={headlineImage.image.altText} />
    </div>
    <div className="p-4 justify-between w">
      <div className="flex items-baseline">
        <h3 className="font-bold text-xl mt-2">{title}</h3>
        <div className="ml-2 text-xs font-bold uppercase text-indigo-700 bg-indigo-200 rounded-full px-2">
          {publicationStart}
        </div>
      </div>
      <div className="mt-2 text-gray-800 leading-snug">
        <RichText json={content.json} />
      </div>
    </div>
  </div>
);

export default NewsArticleCard;