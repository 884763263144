import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import FormField from '../../../atoms/Form/FormField';
import Label from '../../../atoms/Form/Label';
import InputField from '../../../atoms/Form/InputField';
import DropDown from '../../../atoms/Form/DropDown';
import TextArea from '../../../atoms/Form/TextArea';
import SecondaryButton from '../../../atoms/Button/SecondaryButton';
import PrimaryButton from '../../../atoms/Button/PrimaryButton';
import Message from '../../../atoms/Message/Message';
import { sendContactRequest } from '../../../../helpers/api';
import Honeypot from '../../../atoms/Form/Honeypot';

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({});

  const honeyPot = {
    name: 'faxNumber',
    value: ''
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        source: '',
        message: '',
        [honeyPot.name]: honeyPot.value,
      }}
      onReset={() => {
        setStatus({});
      }}
      onSubmit={async (values, { resetForm }) => {
        if(!!values[honeyPot.name]) {
          return;
        }
        setSubmitting(true);
        setStatus({
          type: 'info',
          message: 'Submitting your contact request. Please wait.',
        });
        try {
          await sendContactRequest(values);
          resetForm();
          setStatus({
            type: 'success',
            message: `Thank you ${values.firstName} for contacting us. We will come back to you as fast as possible.`,
          });
          setTimeout(() => setStatus({}), 4000);
        } catch (e) {
          setStatus({
            type: 'error',
            message: `Ups, the following error occurred: ${e.message}`,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form className="bg-white shadow-lg p-8 rounded">
        <h4 className="text-lg font-semibold tracking-wide">Get in touch</h4>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Label className="mb-1 w-48 md:mr-2" htmlFor="firstName">First name</Label>
          <Field as={InputField} type="text" name="firstName" placeholder="Your first name" required />
        </FormField>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Label className="mb-1 w-48 md:mr-2" htmlFor="lastName">Last name</Label>
          <Field as={InputField} type="text" name="lastName" placeholder="Your last name" required />
        </FormField>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Label className="mb-1 w-48 md:mr-2" htmlFor="email">Email</Label>
          <Field as={InputField} type="email" name="email" placeholder="Your email address" required  />
        </FormField>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Label className="mb-1 w-48 md:mr-2" htmlFor="company">Company</Label>
          <Field as={InputField} type="text" name="company" placeholder="Optional" />
        </FormField>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Label className="mb-1 w-48 md:mr-2" htmlFor="source">How did you find us?</Label>
          <Field as={DropDown} name="source" options={[
            {
              value: 'exhibiton',
              label: 'From an exhibition',
            },
            {
              value: 'search-engine',
              label: 'Via a search engine',
            },
            {
              value: 'other-client',
              label: 'From another client',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ]} required />
        </FormField>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Label className="mb-1 w-48 md:mr-2" htmlFor="message">Your message</Label>
          <Field as={TextArea} rows={10} name="message" placeholder="Your message to us" required />
        </FormField>
        <FormField className="flex flex-col items-baseline my-4 md:flex-row">
          <Field as={Honeypot} type="text" name={honeyPot.name} />
        </FormField>
        <div className="mt-4 text-right">
          <SecondaryButton type="reset" disabled={submitting}>Reset</SecondaryButton>
          <PrimaryButton className="ml-4" type="submit" disabled={submitting}>Send message</PrimaryButton>
        </div>
        <div className={`mt-8 ${status.message ? 'block' : 'hidden'}`}>
          <Message type={status.type}>{status.message}</Message>
        </div>
      </Form>
    </Formik>
  );
};

export default ContactForm;