import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledHeroTitle = styled.div.attrs((props) => {
  let classes = [];

  switch (props.level) {
    case 1: {
      classes = [...classes, 'text-5xl', 'font-semibold', 'tracking-wide', 'md:text-6xl'];
      break;
    }
    case 2: {
      classes = [...classes, 'text-xl', 'font-thin', 'tracking-widest'];
      break;
    }
    default: {
      classes = [...classes];
      break;
    }
  }

  if (props.light) {
    classes = [...classes, 'text-white'];
  } else {
    classes = [...classes, 'text-gray-900'];
  }

  return {
    className: classes.join(' '),
  };
})``;

const HeroTitle = ({ title, ...props }) => (
  <StyledHeroTitle {...props}>
    {title}
  </StyledHeroTitle>
);

HeroTitle.propTypes = {
  title: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  light: PropTypes.bool.isRequired,
};

HeroTitle.defaultProps = {
  level: 1,
  light: true,
};

export default HeroTitle;