import * as React from 'react';
import NewsletterSignupForm from '../components/organisms/CustomModules/NewsletterSignupForm/NewsletterSignupForm';
import ContactForm from '../components/organisms/CustomModules/ContactForm/ContactForm';

export const renderCustomModule = (format, type, identifier) => {
  const CustomModule = customModules[type][identifier][format];

  return !!CustomModule ? (<CustomModule />) : (<div>Ups... custom module {type} / {identifier} was not found.s</div>);
};

const customModules = {
  Form: {
    'form-newsletter-signup-full': {
      detail: () => (
        <div className="w-full md:w-2/3 lg:w-1/2 m-auto">
          <NewsletterSignupForm />
        </div>
      ),
    },
    'form-contact-full': {
      detail: () => (
        <div className="w-full md:w-2/3 lg:w-1/2 m-auto">
          <ContactForm />
        </div>
      ),
    },
  },
};


